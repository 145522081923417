
@charset "utf-8";

// Define defaults for each variable.

$base-font-family:    -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif !default;
$code-font-family:    "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:      20px !default;
$base-font-weight:    400 !default;
$small-font-size:     $base-font-size * 0.875 !default;
$base-line-height:    1.5 !default;

$spacing-unit:        30px !default;

$table-text-align:    left !default;

// Width of the content area
$content-width:       800px !default;

$on-palm:             600px !default;
$on-laptop:           800px !default;

$on-medium:           $on-palm !default;
$on-large:            $on-laptop !default;

$text-color:          "black";
$background-color:    "black";
$link-base-color:     "black";
$link-visited-color:  "black";
$link-hover-color:    "black";
$brand-color :        "black";


html {
  font-size: $base-font-size;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: #000000;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-wrap: break-word;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: calc($spacing-unit / 2);
}

hr {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
  color: $link-base-color;
  text-decoration: none;

  &:visited {
    color: $link-visited-color;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

/**
* The current navigation item
*/
.current {
  color: green;
  }

@media only screen and (max-width: 991px) {
  body {
    font-size: 30px !important;
  }

  #header-image {
    width: 300px;
    margin: auto;
  }

  #header-lookout {
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
     justify-content: center;
     gap: 10px;
     margin-top: 30px;
     margin-bottom: 30px;
     text-align: center;
  }

  .content {
    margin-left: 0px !important;
  }

  #header-intro, #header-cookie-notice {
    text-align: center;
  }

  #header-intro {
    font-size: 36px !important;
  }

  #header-cookie-notice {
    font-size: 20px !important;
  }

  .header-list-item {
    display: inline !important;
    margin-right: 20px;
  }

  nav {
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
  #header {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 360px;
    margin-bottom: 50px;
  }

  #header-image {
    width: 360px;
  }

#header-lookout {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
   gap: 10px;
   margin-top: 10px;
   margin-bottom: 10px;
   text-align: start;
}

  #header-content {
    margin-left: 10px;
    margin-right: 10px;
  }
}

#header-intro {
  margin-top: 20px;
  font-size: 24px;
  color: #FFFFFF;
}

#header-cookie-notice {
  margin-top: 15px;
  color: #FFFFFF;
  font-size: 12px;
}

.header-list-item {
  display: block;
  color: #67E3E4;
}

.content {
  background-color: #FFFFFF;
  margin-left: 360px;
  min-height: 100vh;
  padding-left: 80px;
  padding-right: 80px;
}

.content-area {
  max-width: 800px;
  margin-bottom: 80px;
}

#content-episodes-list {
  list-style: none;
}

.content-title {
  margin-top: 80px;
  margin-bottom: 30px;
}

.content-list-item {
  color: #000000;
}

.content-list-item-divider + .content-list-item-divider {
   margin-top: 10px;
   border-top: 1px solid #67E3E4;
   padding-top: 10px;
}
